import React from "react";
import Button from "../../UI/button/button";
import classes from "./ButtonListLeft.module.css";

const buttonListLeft = (props) => {
  return (
    <div className={classes.buttonList}>
      <Button type="left">&lt;</Button>
    </div>
  );
};

export default buttonListLeft;

import React from "react";
import Logo from "../../LogoInfo/LogoInfo";
import DevID from "../../UI/DevID/DevID";
import classes from "./ContactsInfo.module.css";

const ContactsInfo = (props) => {
  return (
    <div className={classes.content}>
      <Logo />
      <div className={classes.AboutText}>
        <p>
          Se pretende receber mais alguma informação ou acompanhar o projeto por
          favor envie um e-mail para{" "}
          <a
            href="mailto:geral@dialogosilenciosos.pt"
            target="_blank"
            rel="noreferrer"
          >
            geral@dialogosilenciosos.pt
          </a>{" "}
          e entraremos em contacto.
        </p>
      </div>
      <div className={classes.EndDash}>-</div>
      <DevID />
    </div>
  );
};

export default ContactsInfo;

import React from "react";
import BlackBackdrop from "../UI/BlackBackdrop/BlackBackdrop";
import classes from "./About.module.css";
import AboutInfo from "./aboutInfo/AboutInfo";

const About = (props) => {
  return (
    <div className={classes.content}>
      <BlackBackdrop />
      <AboutInfo />
    </div>
  );
};

export default About;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideMenu_sideMenuList__2mdUL {\n  right: 3%;\n  top: 60px;\n  position: fixed;\n  justify-content: center;\n  width: 300px;\n  background-color: white;\n  z-index: 500;\n  transition: all 0.3s ease-in-out;\n  box-shadow: 5px 2px 2px rgb(78, 78, 78);\n}\n\n.SideMenu_sideMenuList__2mdUL ul {\n  margin-left: 0;\n  padding-left: 0;\n  list-style-type: none;\n  display: flex;\n  flex-direction: column;\n}\n\n.SideMenu_sideMenuList__2mdUL li {\n  margin-left: 0;\n  padding-left: 1rem;\n  margin-bottom: 0.5rem;\n  align-self: flex-start;\n  list-style-type: none;\n  cursor: pointer;\n}\n\n.SideMenu_sideMenuList__2mdUL a {\n  color: black;\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://src/sideMenu/SideMenu.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,SAAS;EACT,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,gCAAgC;EAChC,uCAAuC;AACzC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".sideMenuList {\n  right: 3%;\n  top: 60px;\n  position: fixed;\n  justify-content: center;\n  width: 300px;\n  background-color: white;\n  z-index: 500;\n  transition: all 0.3s ease-in-out;\n  box-shadow: 5px 2px 2px rgb(78, 78, 78);\n}\n\n.sideMenuList ul {\n  margin-left: 0;\n  padding-left: 0;\n  list-style-type: none;\n  display: flex;\n  flex-direction: column;\n}\n\n.sideMenuList li {\n  margin-left: 0;\n  padding-left: 1rem;\n  margin-bottom: 0.5rem;\n  align-self: flex-start;\n  list-style-type: none;\n  cursor: pointer;\n}\n\n.sideMenuList a {\n  color: black;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideMenuList": "SideMenu_sideMenuList__2mdUL"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SculptureImg_sculptureImage__1QL8x {\n  height: 120px;\n  width: 120px;\n  overflow: hidden;\n  background-color: black;\n}\n.SculptureImg_sculptureImage__1QL8x img {\n  height: 120px;\n  max-width: initial;\n}\n", "",{"version":3,"sources":["webpack://src/main/dialogueModal/sculptureImg/SculptureImg.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".sculptureImage {\n  height: 120px;\n  width: 120px;\n  overflow: hidden;\n  background-color: black;\n}\n.sculptureImage img {\n  height: 120px;\n  max-width: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sculptureImage": "SculptureImg_sculptureImage__1QL8x"
};
export default ___CSS_LOADER_EXPORT___;

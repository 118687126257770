import React from "react";
import classes from "./MoreInfo.module.css";

const MoreInfo = (props) => {
  return (
    <div className={classes.moreInfo}>
      <ul>
        <li>
          <strong>{props.sculpture.sculpture_name}</strong>
        </li>
        <li>
          <strong>
            {props.sculpture.sculpture_author +
              ", " +
              props.sculpture.sculpture_year}
          </strong>
        </li>

        <li>
          <a
            href={props.sculpture.sculpture_cmst_url}
            target="_blank"
            rel="noreferrer"
          >
            Página Oficial
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MoreInfo;

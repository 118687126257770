// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main_content__2phSU {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin-top: 2rem;\n  height: 880px;\n}\n", "",{"version":3,"sources":["webpack://src/main/Main.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".content {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin-top: 2rem;\n  height: 880px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Main_content__2phSU"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogueList_dialogueList__2xQqy {\n  display: flex;\n  height: 880px;\n  width: 1600px;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/main/dialogueList/DialogueList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".dialogueList {\n  display: flex;\n  height: 880px;\n  width: 1600px;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogueList": "DialogueList_dialogueList__2xQqy"
};
export default ___CSS_LOADER_EXPORT___;

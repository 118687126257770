import React from "react";

import classes from "./DialogueList.module.css";
import Dialogue from "./dialogue/Dialogue";
import Logo from "../../Logo/Logo";

import { useSelector } from "react-redux";

const getPosition = (init, final) => {
  let number1 = 0;
  let number2 = 0;
  while (number1 === number2) {
    number1 = Math.random() * (final - init);
    number2 = Math.random() * (final - init);
  }
  return [number1, number2];
};

const DialogueList = (props) => {
  const first_dialogue = useSelector((state) => state.first_dialogue);
  const filteredDialogues = useSelector((state) => state.filteredDialogues);
  const dialogueNumberByPage = useSelector(
    (state) => state.dialogueNumberByPage
  ); // Default : 26

  let last_dialogue = first_dialogue + dialogueNumberByPage;

  const dialogueShowCase = filteredDialogues.slice(
    first_dialogue,
    last_dialogue
  );

  let size = dialogueShowCase.length;
  let randomNum = [];
  if (size !== 0) randomNum = getPosition(0, size);
  const dialogueList = dialogueShowCase.map((dialogue) => (
    <Dialogue key={dialogue.dialogue_id} dialogue={dialogue} />
  ));
  dialogueList.splice(randomNum[0], 0, <Logo key="logo1" />);
  dialogueList.splice(randomNum[1], 0, <Logo key="logo2" />);

  return <div className={classes.dialogueList}>{dialogueList}</div>;
};

export default DialogueList;

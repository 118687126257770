import React from "react";
import Main from "./main/Main";
import About from "./about/About";
import Contacts from "./contacts/Contacts";
import NavButton from "./UI/navButton/NavButton";
import Submissions from "./submissions/Submissions";
import { Route } from "react-router-dom";
import SideMenu from "./sideMenu/SideMenu";
import Backdrop from "./UI/Backdrop/Backdrop";
import ReactGA from "react-ga";

import { useSelector } from "react-redux";

const TRACKING_ID = "G-60LRMYFCZY"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const sideMenu = useSelector((state) => state.sideMenu);
  const backdrop = useSelector((state) => state.backdrop);
  return (
    <div>
      <NavButton>&#9776;</NavButton>
      {sideMenu && <SideMenu />}
      {backdrop && <Backdrop />}
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/contacts">
        <Contacts />
      </Route>
      <Route exact path="/submissions">
        <Submissions />
      </Route>
      <Route exact path="/dialogues">
        <Main />
      </Route>
      <Route exact path="/">
        <Main />
      </Route>
    </div>
  );
};

export default App;

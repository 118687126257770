import React from "react";
import Logo from "../../LogoInfo/LogoInfo";
import DevID from "../../UI/DevID/DevID";

import classes from "./AboutInfo.module.css";

const AboutInfo = (props) => {
  return (
    <div className={classes.content}>
      <Logo />
      <div className={classes.AboutText}>
        <p>Isto é um arquivo vivo.</p>
        <p>
          Este arquivo é parte de uma investigação de doutoramento que procura
          compreender a relação entre o património escultório do Museu
          Internacional de Escultura Contemporânea de Santo Tirso e as suas
          audiências não especializadas.
        </p>
        <p>
          De forma a perceber mais sobre esta relação considerou-se adequado a
          criação de um arquivo que juntasse as interpretações que as audiências
          criam das esculturas que habitam o espaço público da cidade. Este
          arquivo vem reunir desenhos, textos e sons que tornam esta plataforma
          viva.
        </p>
        <p>
          <strong>TODOS</strong> os diálogos são bem vindos.
        </p>
        <p>
          Descobre como submeter o teu diálogo{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScf24hgOZUsKbmECSaUXCuzaFg39WOEOzDTheI9dg1GaAA6ng/viewform"
            target="_blank"
            rel="noreferrer"
          >
            aqui
          </a>
          .
        </p>
      </div>
      <div className={classes.EndDash}>-</div>
      <DevID />
    </div>
  );
};

export default AboutInfo;

import React from "react";
import Button from "../../UI/button/button";
import classes from "./ButtonListRight.module.css";

const buttonListRight = (props) => {
  return (
    <div className={classes.buttonList}>
      <Button type="right">&gt;</Button>
      {/* <Button type="filter"></Button> */}
      <Button type="sculptureFilter"></Button>
    </div>
  );
};

export default buttonListRight;

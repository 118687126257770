import React from "react";
import classes from "./Dialogue.module.css";
import { useDispatch } from "react-redux";
import host from "../../../model/host";
import mediaServer from "../../../model/mediaServer";

const sizeFixer = (phrase) => {
  if (phrase.length > 115) {
    let fixedPhrase = phrase.slice(0, 115);
    return fixedPhrase.concat(" (...)");
  } else return phrase;
};

const Dialogue = (props) => {
  const dispatch = useDispatch();

  const fetchSculptureHandler = async () => {
    const response = await fetch(
      host + "/api/sculptures/number/" + props.dialogue.dialogue_sculpture_id
    );
    const sculpture = await response.json();
    dispatch({ type: "selectSculpture", modalSelectedSculpture: sculpture });
    dispatch({ type: "selectDialogue", selectedDialogue: props.dialogue });
    dispatch({ type: "modalToggle" });
  };
  if (props.dialogue.dialogue_type === 1) {
    return (
      <div onClick={fetchSculptureHandler} className={classes.dialogue}>
        <div className={classes.quote}>
          <p
            dangerouslySetInnerHTML={{
              __html: sizeFixer(props.dialogue.dialog_phrase),
            }}
          />
        </div>
      </div>
    );
  }
  if (props.dialogue.dialogue_type === 2) {
    let mediaLocation = mediaServer + props.dialogue.dialog_phrase;
    return (
      <div onClick={fetchSculptureHandler} className={classes.dialogue}>
        <div className={classes.image}>
          <img src={mediaLocation} alt="Dialogo silencioso" />
        </div>
      </div>
    );
  }
  if (props.dialogue.dialogue_type === 3) {
    let mediaLocation = mediaServer + props.dialogue.dialog_phrase;
    return (
      <div onClick={fetchSculptureHandler} className={classes.dialogue}>
        <div className={classes.sound}>
          <audio controls>
            <source src={mediaLocation} type="audio/mpeg" />
            <source src={mediaLocation} type="audio/ogg" />
            <source src={mediaLocation} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    );
  }
  if (props.dialogue.dialogue_type === 4) {
    let mediaLocation = mediaServer + props.dialogue.dialog_phrase;
    return (
      <div onClick={fetchSculptureHandler} className={classes.dialogue}>
        <div className={classes.movie}>
          <video controls>
            <source src={mediaLocation} type="video/mp4" />
            <source src={mediaLocation} type="video/ogg" />
            Your browser does not support the Video element.
          </video>
        </div>
      </div>
    );
  } else {
    return (
      <div onClick={fetchSculptureHandler} className={classes.dialogue}>
        <div className={classes.danger}>
          <p>
            Dialogue Type Unknown. Please add a Valid Dialogue Type to Database
          </p>
        </div>
      </div>
    );
  }
};

export default Dialogue;

import React from "react";
import classes from "./RadioButton.module.css";
import { useDispatch } from "react-redux";

const RadioButton = (props) => {
  const dispatch = useDispatch();
  const sculptureChangeHandler = (e) => {
    dispatch({
      type: "setSculptureSubmission",
      sculpture: e.target.value,
    });
  };
  return (
    <li className={classes.itemForm}>
      <input
        type="radio"
        name="selectedSculpture"
        value={props.sculpture.sculpture_number}
        id={props.sculpture.sculpture_number}
        ref={props.reference}
        onChange={sculptureChangeHandler}
      />

      <label htmlFor={props.sculpture.sculpture_number}>
        <img
          src={props.sculpture.sculpture_image}
          alt={props.sculpture.sculpture_name}
        />
      </label>
    </li>
  );
};

export default RadioButton;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonListLeft_buttonList__3vv6C {\n  width: 40px;\n  height: 880px;\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/main/buttonListLeft/ButtonListLeft.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".buttonList {\n  width: 40px;\n  height: 880px;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonList": "ButtonListLeft_buttonList__3vv6C"
};
export default ___CSS_LOADER_EXPORT___;

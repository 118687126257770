import React from "react";

import classes from "./SubmissionsInfo.module.css";
import { useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import RadioButton from "./RadioButton/RadioButton";

const SubmissionsInfo = (props) => {
  const sculptureRef = useRef();
  const ageRef = useRef();
  const dialogueTextRef = useRef();
  const dialogueImgRef = useRef();
  const dialogueAudioRef = useRef();
  const dialogueVideoRef = useRef();

  const dispatch = useDispatch();
  const dialogueType = useSelector((state) => state.submissionDialogueType);
  const dialogueSculpture = useSelector((state) => state.submissionSculpture);

  const typeText = dialogueType === "1";
  const typeImage = dialogueType === "2";
  const typeSound = dialogueType === "3";
  const typeVideo = dialogueType === "4";

  const htmlSculpt = props.sculptures.map((sculpture) => {
    return <RadioButton sculpture={sculpture} reference={sculptureRef} />;
  });

  const dialogueTypeHandler = (event) => {
    return dispatch({
      type: "dialogueType",
      dialogueType: event.target.value,
    });
  };

  const submitHandler = (event) => {
    var numbers = /^[0-9]+$/;

    if (dialogueSculpture === null) {
      alert("ERROR sculpture");
      return dispatch({
        type: "sculptureError",
      });
    }
    if (
      !ageRef.current.value.match(numbers) ||
      ageRef.current.value < 0 ||
      ageRef.current.value > 100
    ) {
      alert("ERROR AGE");
      return dispatch({
        type: "ageError",
      });
      return;
    }
    if (typeText && dialogueTextRef.current.value === "") {
      alert("ERROR DIALOGUE");
      return dispatch({
        type: "textError",
      });
    }

    if (typeImage && dialogueImgRef.current.value === "") {
      alert("ERROR DIALOGUE IMAGE");
      return;
    }

    if (typeSound && dialogueAudioRef.current.value === "") {
      alert("ERROR DIALOGUE SOUND");
      return;
    }

    if (typeVideo && dialogueVideoRef.current.value === "") {
      alert("ERROR DIALOGUE VIDEO");
      return;
    }

    const fullDialogue = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sculpture: dialogueSculpture,
        type: dialogueType,
        age: ageRef.current.value,
        dialogue: dialogueTextRef.current.value,
      }),
    };

    alert(
      "Escultura " +
        dialogueSculpture +
        "Tipo de Dialogo" +
        dialogueType +
        "Idade: " +
        ageRef.current.value +
        "Dialogo:" +
        dialogueTextRef.current.value
    );

    return dispatch({
      type: "submissionOK",
    });
  };

  return (
    <div className={classes.content}>
      <h3>Submissões</h3>
      <form onSubmit={submitHandler}>
        <p>1. Escolha a escultura</p>

        <ul className={classes.sculptureList}>{htmlSculpt}</ul>
        <div className={classes.interventionType}>
          <label htmlFor="type">2. Escolha o tipo de intervenção</label>
          <select name="type" id="type" onChange={dialogueTypeHandler}>
            <option value="1">Texto</option>
            {/* <option value="2">Imagem</option>
            <option value="3">Som</option>
            <option value="4">Video</option> */}
          </select>
        </div>

        <div className={classes.interventionAge}>
          <label htmlFor="age">3. Coloque a sua Idade: </label>

          <input
            type="text"
            id="age"
            className={classes.age}
            ref={ageRef}
          ></input>
        </div>

        {typeText ? (
          <div className={classes.interventionText}>
            <label htmlFor="dialogue">4. Escreva a sua intervenção </label>
            <br /> <br />
            <textarea
              id="dialogue"
              ref={dialogueTextRef}
              placeholder=" Escreva aqui a sua intervenção"
            />
          </div>
        ) : typeImage ? (
          <div>
            <label htmlFor="imagem">3. Escolha o ficheiro a carregar</label>
            <br /> <br />
            <input type="file" id="imagem" ref={dialogueImgRef}></input>
          </div>
        ) : typeSound ? (
          <div>
            <label htmlFor="sound">3. Escolha o filheiro a carregar</label>
            <br /> <br />
            <input type="file" id="sound" ref={dialogueAudioRef}></input>
          </div>
        ) : typeVideo ? (
          <div>
            <label htmlFor="video">4. Escolha o filheiro a carregar</label>
            <br /> <br />
            <input type="file" id="videos" ref={dialogueVideoRef}></input>
          </div>
        ) : null}
        <div>
          <p>
            Aceite os <a href="#">Termos e condições</a>
          </p>
          <input type="radio" name="terms" id="terms" required />
          <label htmlFor="terms">Aceito</label>
        </div>

        <button type="submit">Submeter</button>
      </form>
    </div>
  );
};

export default SubmissionsInfo;

import React from "react";

const DevID = (props) => {
  return (
    <div>
      <p>
        ©Página criada e desenvolvida por{" "}
        <a
          href="mailto:ricardoimdacosta@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          Ricardo da Costa
        </a>
      </p>
    </div>
  );
};
export default DevID;

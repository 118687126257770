// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MoreInfo_moreInfo__2pt5H {\n  display: flex;\n  height: 30%;\n  width: 300px;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n.MoreInfo_moreInfo__2pt5H ul {\n  margin-left: 0;\n  padding-left: 0;\n  list-style-type: none;\n  color: white;\n  font-size: 15px;\n  display: flex;\n  flex-direction: column;\n}\n.MoreInfo_moreInfo__2pt5H li {\n  margin-left: 0;\n  padding-left: 1rem;\n  margin-bottom: 0.5rem;\n  list-style-type: none;\n  text-align: center;\n}\n.MoreInfo_moreInfo__2pt5H a {\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://src/main/dialogueModal/moreInfo/MoreInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":[".moreInfo {\n  display: flex;\n  height: 30%;\n  width: 300px;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n.moreInfo ul {\n  margin-left: 0;\n  padding-left: 0;\n  list-style-type: none;\n  color: white;\n  font-size: 15px;\n  display: flex;\n  flex-direction: column;\n}\n.moreInfo li {\n  margin-left: 0;\n  padding-left: 1rem;\n  margin-bottom: 0.5rem;\n  list-style-type: none;\n  text-align: center;\n}\n.moreInfo a {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreInfo": "MoreInfo_moreInfo__2pt5H"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Check from "./check/Check";
import classes from "./SculptureFilterMenu.module.css";

import { useSelector } from "react-redux";

const SculptureFilterMenu = (props) => {
  const selectedSculptures = useSelector((state) => state.selectedSculptures);
  const allSculptures = useSelector((state) => state.sculptures);

  const htmlSculpt = allSculptures.map((sculpture) => {
    let sculpt = selectedSculptures.includes(
      parseInt(sculpture.sculpture_number)
    );

    return (
      <Check
        key={sculpture.sculpture_number}
        sculpture={sculpture}
        check={sculpt}
        sculptures={selectedSculptures}
      />
    );
  });

  return (
    <div>
      <div className={classes.filterMenuList}>
        <h3>Selecione as esculturas pretendidas:</h3>

        <form>
          <ul>{htmlSculpt}</ul>
        </form>
      </div>
    </div>
  );
};

export default SculptureFilterMenu;

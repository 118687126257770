import React from "react";
import BlackBackdrop from "../UI/BlackBackdrop/BlackBackdrop";
import classes from "./Submissions.module.css";
import SubmissionsInfo from "./submissionsInfo/SubmissionsInfo";

import { useSelector, useDispatch } from "react-redux";

//const host = "http://localhost:3000";
const host = "https://api.dialogosilenciosos.pt";

const Submissions = (props) => {
  const dispatch = useDispatch();
  const fetchSculpturesHandler = async () => {
    const response = await fetch(host + "/api/sculptures/get-all");
    const list = await response.json();
    dispatch({
      type: "populateSculptures",
      sculptures: list,
    });
    dispatch({
      type: "populateSculpturesFilter",
      sculpture_array: list.map((sculpture) =>
        parseInt(sculpture.sculpture_number)
      ),
    });
    dispatch({
      type: "setSculpturesSize",
      length: list.length,
    });
    dispatch({ type: "firstRunSculpture" });
  };

  const sculptures = useSelector((state) => state.sculptures);
  if (sculptures.length === 0) {
    fetchSculpturesHandler();
    return <div></div>;
  } else {
    return (
      <div className={classes.content}>
        <BlackBackdrop />
        <SubmissionsInfo sculptures={sculptures} />
      </div>
    );
  }
};

export default Submissions;

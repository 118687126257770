import React from "react";
import BlackBackdrop from "../UI/BlackBackdrop/BlackBackdrop";
import classes from "./Contacts.module.css";
import ContactsInfo from "./contactsInfo/ContactsInfo";

const Contacts = (props) => {
  return (
    <div className={classes.content}>
      <BlackBackdrop />

      <ContactsInfo />
    </div>
  );
};

export default Contacts;

import { createStore } from "redux";

const initialState = {
  newDialogueType: 0,
  newDialoguePhrase: "",
  newDialogueAge: "",
  newDialogueSculpture: "",
  selectedSculptures: [],
  dialogues: [],
  first_time_dialogues: true,
  first_time_sculptures: true,
  first_time_sculpturesFilter: true,
  dialoguesNumber: 0,
  selectedDialogue: [],
  sculptures: [],
  sculpturesNumber: 0,
  first_dialogue: 0,
  initialDialogue: 0,
  ageFilterBottom: -1,
  ageFilterTop: 100,
  backdrop: false,
  blackBackdrop: false,
  sideMenu: false,
  filterMenu: false,
  sculptureFilterMenu: false,
  dialogueModal: false,
  filteredDialogues: [],
  modalSelectedSculpture: [],
  activeFilter: false,
  activeRight: true,
  activeLeft: true,
  dialogueNumberByPage: 26,
  submissionDialogueType: "1",
  submissionSculpture: null,
  sculptureError: false,
  textError: false,
  ageError: false,
  SubmissionOK: false,
};

const qdReducer = (state = initialState, action) => {
  if (action.type === "textError") {
    return {
      ...state,
      textError: true,
    };
  }

  if (action.type === "ageError") {
    return {
      ...state,
      ageError: true,
    };
  }
  if (action.type === "sculptureError") {
    return {
      ...state,
      sculptureError: true,
    };
  }
  if (action.type === "submissionOK") {
    return {
      ...state,
      SubmissionOK: true,
    };
  }

  if (action.type === "setSculptureSubmission") {
    return {
      ...state,
      submissionSculpture: action.sculpture,
    };
  }

  if (action.type === "dialogueType") {
    return {
      ...state,
      submissionDialogueType: action.dialogueType,
    };
  }

  if (action.type === "setSculptureFilter") {
    if (state.selectedSculptures === action.sculptures)
      return { ...state, first_time_dialogues: true };
    else
      return {
        ...state,
        selectedSculptures: action.sculptures,
        first_time_dialogues: true,
      };
  }
  if (action.type === "populateSculpturesFilter") {
    return {
      ...state,
      selectedSculptures: [],
    };
  }
  if (action.type === "setFilterAge") {
    if (
      action.limits[0] === state.ageFilterBottom &&
      action.limits[1] === state.ageFilterTop
    ) {
      return state;
    } else {
      return {
        ...state,
        ageFilterBottom: action.limits[0],
        ageFilterTop: action.limits[1],
        first_time_dialogues: true,
        first_time_sculptures: true,
      };
    }
  }
  if (action.type === "firstRunDialogue") {
    return { ...state, first_time_dialogues: false, first_dialogue: 0 };
  }
  if (action.type === "setDialogueSize") {
    if (state.first_time_dialogues)
      return { ...state, dialoguesNumber: action.length };
  }

  if (action.type === "populateDialogues") {
    let dialoguesFilter = state.dialogues;

    if (state.selectedSculptures.length !== 0) {
      dialoguesFilter = action.dialogues.filter(
        (dialogue) =>
          dialogue.dialog_age >= state.ageFilterBottom &&
          dialogue.dialog_age <= state.ageFilterTop &&
          state.selectedSculptures.includes(dialogue.dialogue_sculpture_id)
      );
    }

    if (state.first_time_dialogues)
      return {
        ...state,
        dialogues: action.dialogues,
      };
    return {
      ...state,
      filteredDialogues: dialoguesFilter,
    };
  }

  if (action.type === "selectDialogue") {
    return { ...state, selectedDialogue: action.selectedDialogue };
  }

  //Sculptures Database
  if (action.type === "selectSculpture") {
    return { ...state, modalSelectedSculpture: action.modalSelectedSculpture };
  }
  if (action.type === "firstRunSculpture") {
    return { ...state, first_time_sculptures: false };
  }
  if (action.type === "setSculptureSize") {
    if (state.first_time_sculptures)
      return { ...state, sculpturesNumber: action.length };
  }
  if (action.type === "populateSculptures") {
    if (state.first_time_sculptures)
      return {
        ...state,
        sculptures: action.sculptures,
        selectedSculptures: action.sculpture_array,
      };
  }

  // Toggles
  if (action.type === "backdropToggle") {
    return {
      ...state,
      backdrop: !state.backdrop,
      dialogueModal: false,
      sideMenu: false,
      filterMenu: false,
      sculptureFilterMenu: false,
    };
  }
  if (action.type === "modalToggle") {
    return {
      ...state,
      backdrop: !state.backdrop,
      dialogueModal: true,
    };
  }
  if (action.type === "sideMenuToggle") {
    return { ...state, backdrop: !state.backdrop, sideMenu: !state.sideMenu };
  }
  if (action.type === "filterMenuToggle") {
    return {
      ...state,
      backdrop: !state.backdrop,
      filterMenu: !state.filterMenu,
    };
  }

  if (action.type === "sculptureFilterMenuToggle") {
    return {
      ...state,
      backdrop: !state.backdrop,
      sculptureFilterMenu: !state.sculptureFilterMenu,
    };
  }
  //Navigation Buttons
  if (action.type === "rightButton") {
    if (
      state.first_dialogue + state.dialogueNumberByPage + 1 >
      parseInt(state.dialoguesNumber)
    ) {
      return { ...state, first_dialogue: state.first_dialogue };
    }
    return {
      ...state,
      first_dialogue: state.first_dialogue + state.dialogueNumberByPage,
    };
  }

  if (action.type === "leftButton") {
    if (state.first_dialogue - state.dialogueNumberByPage - 1 < 0) {
      return { ...state, first_dialogue: 0 };
    }
    return {
      ...state,
      first_dialogue: state.first_dialogue - state.dialogueNumberByPage,
    };
  }
  return state;
};

const store = createStore(qdReducer);

export default store;

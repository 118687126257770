import React from "react";
import classes from "./Backdrop.module.css";
import { useDispatch } from "react-redux";

const Backdrop = (props) => {
  const dispatch = useDispatch();

  const backdropHandler = () => {
    dispatch({ type: "backdropToggle" });
  };
  return <div className={classes.Backdrop} onClick={backdropHandler}></div>;
};
export default Backdrop;

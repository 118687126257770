import React from "react";
import filterButton from "../../assets/images/funnel.png";
import classes from "./Button.module.css";
import { useSelector, useDispatch } from "react-redux";

const Button = (props) => {
  const first_dialogue = useSelector((state) => state.first_dialogue);
  const dialogueNumber = useSelector((state) => state.filteredDialogues).length;

  const dialogueNumberByPage = useSelector(
    (state) => state.dialogueNumberByPage
  );

  const dispatch = useDispatch();

  const sideMenuHandler = () => {
    dispatch({ type: "sideMenuToggle" });
  };
  const filterMenuHandler = () => {
    dispatch({ type: "filterMenuToggle" });
  };

  const sculptureFilterMenuHandler = () => {
    dispatch({ type: "sculptureFilterMenuToggle" });
  };

  const rightArrowHandler = () => {
    dispatch({ type: "rightButton" });
  };

  const leftArrowHandler = () => {
    dispatch({ type: "leftButton" });
  };

  if (props.type === "filter") {
    return (
      <div className={classes.filterButton} onClick={filterMenuHandler}>
        <img src={filterButton} alt="Dialogos em Silencio" />
      </div>
    );
  }

  if (props.type === "sculptureFilter") {
    return (
      <div
        className={classes.filterButton}
        onClick={sculptureFilterMenuHandler}
      >
        <img src={filterButton} alt="Dialogos em Silencio" />
      </div>
    );
  }

  if (props.type === "hamburger") {
    return (
      <div className={classes.navButton} onClick={sideMenuHandler}>
        {props.children}
      </div>
    );
  }

  if (props.type === "right") {
    if (first_dialogue + dialogueNumberByPage > dialogueNumber) {
      return (
        <div
          className={classes.inactiveArrowButtonRight}
          onClick={rightArrowHandler}
        >
          {props.children}
        </div>
      );
    } else {
      return (
        <div className={classes.arrowButtonRight} onClick={rightArrowHandler}>
          {props.children}
        </div>
      );
    }
  }

  if (props.type === "left") {
    if (first_dialogue - dialogueNumberByPage < 0) {
      return (
        <div
          className={classes.inactiveArrowButtonLeft}
          onClick={leftArrowHandler}
        >
          {props.children}
        </div>
      );
    } else {
      return (
        <div className={classes.arrowButtonLeft} onClick={leftArrowHandler}>
          {props.children}
        </div>
      );
    }
  }

  return <div className={classes.navButton}>{props.children}</div>;
};

export default Button;

import React from "react";
import DialogueList from "./dialogueList/DialogueList";
import ButtonListLeft from "./buttonListLeft/buttonListLeft";
import ButtonListRight from "./buttonListRight/buttonListRight";
//import FilterMenu from "./filterMenu/FilterMenu";
import SculptureFilterMenu from "./sculptureFilterMenu/SculptureFilterMenu";
import DialogueModal from "./dialogueModal/DialogueModal";
import classes from "./Main.module.css";
import Aux from "../hoc/Aux/Aux";
import host from "../model/host";

import { useSelector, useDispatch } from "react-redux";

const Main = (props) => {
  const dispatch = useDispatch();
  const fetchDialoguesHandler = async () => {
    const response = await fetch(host + "/api/dialogues/get-all");
    const orderedList = await response.json();
    const list = orderedList.sort(() => Math.random() - 0.5);

    dispatch({ type: "populateDialogues", dialogues: list });
    dispatch({ type: "setDialogueSize", length: list.length });
    dispatch({ type: "firstRunDialogue" });
  };

  const fetchSculpturesHandler = async () => {
    const response = await fetch(host + "/api/sculptures/get-all");
    const list = await response.json();

    dispatch({
      type: "populateSculptures",
      sculptures: list,
    });
    dispatch({
      type: "populateSculpturesFilter",
      sculpture_array: list.map((sculpture) =>
        parseInt(sculpture.sculpture_number)
      ),
    });
    dispatch({
      type: "setSculpturesSize",
      length: list.length,
    });
    dispatch({ type: "firstRunSculpture" });
  };

  //const filterMenu = useSelector((state) => state.filterMenu);
  const dialogueModal = useSelector((state) => state.dialogueModal);
  const sculptureFilterMenu = useSelector((state) => state.sculptureFilterMenu);
  const dialogues = useSelector((state) => state.dialogues);
  const firstTimeDialogues = useSelector((state) => state.first_time_dialogues);
  if (firstTimeDialogues) fetchDialoguesHandler();
  const firstTimeSculptures = useSelector(
    (state) => state.first_time_sculptures
  );
  if (firstTimeSculptures) fetchSculpturesHandler();

  return (
    <Aux>
      {/* {filterMenu && <FilterMenu />} */}
      {sculptureFilterMenu && <SculptureFilterMenu />}
      {dialogueModal && <DialogueModal />}
      <div className={classes.content}>
        <ButtonListLeft />
        <DialogueList list={dialogues} />
        <ButtonListRight />
      </div>
    </Aux>
  );
};

export default Main;

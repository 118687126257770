// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavButton_navButton__NAW4O {\n  position: absolute;\n  z-index: 100;\n  font-size: 40px;\n  margin: 10px;\n  right: 1px;\n  top: 20px;\n  font-weight: bold;\n  color: black;\n  cursor: pointer;\n  transition: all 0.1s ease-in-out;\n  font-family: MulishVariableFont;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */\n  user-select: none;\n}\n\n.NavButton_navButton__NAW4O:hover {\n  transform: scale(1.2);\n}\n", "",{"version":3,"sources":["webpack://src/UI/navButton/NavButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,UAAU;EACV,SAAS;EACT,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,gCAAgC;EAChC,+BAA+B;EAC/B,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW,EACZ,mBAAmB,EACrB,4BAA4B,EAC7B,2BAA2B;EAClD,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".navButton {\n  position: absolute;\n  z-index: 100;\n  font-size: 40px;\n  margin: 10px;\n  right: 1px;\n  top: 20px;\n  font-weight: bold;\n  color: black;\n  cursor: pointer;\n  transition: all 0.1s ease-in-out;\n  font-family: MulishVariableFont;\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n}\n\n.navButton:hover {\n  transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButton": "NavButton_navButton__NAW4O"
};
export default ___CSS_LOADER_EXPORT___;

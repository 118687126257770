import React from "react";
import classes from "./SideMenu.module.css";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const SideMenu = (props) => {
  const clickHandler = () => {
    dispatch({ type: "backdropToggle" });
  };

  const dispatch = useDispatch();

  return (
    <div className={classes.sideMenuList}>
      <ul>
        <li>
          <Link onClick={clickHandler} to="/dialogues">
            Arquivo
          </Link>
        </li>
        <li>
          <Link onClick={clickHandler} to="/about">
            Sobre
          </Link>
        </li>
        <li>
          {/* <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScf24hgOZUsKbmECSaUXCuzaFg39WOEOzDTheI9dg1GaAA6ng/viewform"
            target="_blank"
            rel="noreferrer"
          >
            Submissões
          </a> */}
          <Link onClick={clickHandler} to="/submissions">
            Submissões
          </Link>
        </li>
        <li>
          <Link onClick={clickHandler} to="/contacts">
            Contactos
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutInfo_content__KnbRn {\n  position: absolute;\n  width: 60%;\n  height: 100%;\n  right: 0;\n  background-color: white;\n  color: Black;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-left: 70px;\n}\n.AboutInfo_AboutText__1RCjO {\n  width: 80%;\n  margin-top: 3rem;\n}\n\n.AboutInfo_EndDash__3YgDB {\n  font-size: 60px;\n  font-weight: bold;\n}\n.AboutInfo_content__KnbRn a {\n  color: black;\n}\n", "",{"version":3,"sources":["webpack://src/about/aboutInfo/AboutInfo.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,YAAY;AACd","sourcesContent":[".content {\n  position: absolute;\n  width: 60%;\n  height: 100%;\n  right: 0;\n  background-color: white;\n  color: Black;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-left: 70px;\n}\n.AboutText {\n  width: 80%;\n  margin-top: 3rem;\n}\n\n.EndDash {\n  font-size: 60px;\n  font-weight: bold;\n}\n.content a {\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "AboutInfo_content__KnbRn",
	"AboutText": "AboutInfo_AboutText__1RCjO",
	"EndDash": "AboutInfo_EndDash__3YgDB"
};
export default ___CSS_LOADER_EXPORT___;

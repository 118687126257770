import React from "react";
import classes from "./DialogueModal.module.css";
import SculptureImg from "./sculptureImg/SculptureImg";
import MoreInfo from "./moreInfo/MoreInfo";
import DialoguePhrase from "./dialoguePhrase/DialoguePhrase";
import { useSelector } from "react-redux";

const DialogueModal = (props) => {
  const sculpture = useSelector((state) => state.modalSelectedSculpture);
  const dialogue = useSelector((state) => state.selectedDialogue);

  return (
    <div className={classes.dialogueModal}>
      <DialoguePhrase
        type={dialogue.dialogue_type}
        phrase={dialogue.dialog_phrase}
      />
      <MoreInfo sculpture={sculpture[0]} />
      <SculptureImg imgSrc={sculpture[0].sculpture_image} />
    </div>
  );
};

export default DialogueModal;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_Logo__3zCx9 {\n  width: 210px;\n  height: 210px;\n  background-color: White;\n  margin: 5px;\n  display: inline-block;\n  transition: all 0.3s ease-in-out;\n  cursor: pointer;\n}\n\n.Logo_Logo__3zCx9 img {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,qBAAqB;EACrB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".Logo {\n  width: 210px;\n  height: 210px;\n  background-color: White;\n  margin: 5px;\n  display: inline-block;\n  transition: all 0.3s ease-in-out;\n  cursor: pointer;\n}\n\n.Logo img {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Logo": "Logo_Logo__3zCx9"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import classes from "./DialoguePhrase.module.css";
import mediaServer from "../../../model/mediaServer";
const DialoguePhrase = (props) => {
  const phrase = "&#8220" + props.phrase + "&#8221";

  if (props.type === 1) {
    return (
      <div className={classes.dialoguePhrase}>
        <p
          dangerouslySetInnerHTML={{
            __html: phrase,
          }}
        />
      </div>
    );
  }
  if (props.type === 2) {
    let mediaLocation = mediaServer + props.phrase;
    return (
      <div className={classes.dialogueImage}>
        <img src={mediaLocation} alt="Dialogo silencioso" />
      </div>
    );
  }

  if (props.type === 3) {
    let mediaLocation = mediaServer + props.phrase;
    return (
      <div className={classes.dialogueSound}>
        <audio controls>
          <source src={mediaLocation} type="audio/mpeg" />
          <source src={mediaLocation} type="audio/ogg" />
          <source src={mediaLocation} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }
  if (props.type === 4) {
    let mediaLocation = mediaServer + props.phrase;
    return (
      <div className={classes.dialogueVideo}>
        <video controls>
          <source src={mediaLocation} type="video/mp4" />
          <source src={mediaLocation} type="video/ogg" />
          Your browser does not support the Video element.
        </video>
      </div>
    );
  } else {
    return (
      <div className={classes.dialogueDanger}>
        <p>
          Dialogue Type Unknown. Please add a Valid Dialogue Type to Database
        </p>
      </div>
    );
  }
};

export default DialoguePhrase;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlackBackdrop_Backdrop__Kz8UL {\n  width: 40%;\n  height: 100%;\n  position: fixed;\n  left: 0;\n  top: 0;\n  background-color: Black;\n  transition: all 0.3s ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://src/UI/BlackBackdrop/BlackBackdrop.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,OAAO;EACP,MAAM;EACN,uBAAuB;EACvB,gCAAgC;AAClC","sourcesContent":[".Backdrop {\n  width: 40%;\n  height: 100%;\n  position: fixed;\n  left: 0;\n  top: 0;\n  background-color: Black;\n  transition: all 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": "BlackBackdrop_Backdrop__Kz8UL"
};
export default ___CSS_LOADER_EXPORT___;

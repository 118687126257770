import React from "react";
import classes from "./Check.module.css";
import { useSelector, useDispatch } from "react-redux";

const Check = (props) => {
  const dispatch = useDispatch();

  let sculpturesFinal = useSelector((state) => state.selectedSculptures);

  const sculptureSelectionHandler = (e) => {
    let final = [];
    const item = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      sculpturesFinal.unshift(parseInt(item));
      final = sculpturesFinal;
    } else {
      final = sculpturesFinal.filter((element) => element != item);
    }
    dispatch({
      type: "setSculptureFilter",
      sculptures: final,
    });
  };

  return (
    <li className={classes.item}>
      <input
        type="checkbox"
        id={"sc" + props.sculpture.sculpture_number}
        value={props.sculpture.sculpture_number}
        onChange={sculptureSelectionHandler}
        checked={props.check}
      />
      <label htmlFor={"sc" + props.sculpture.sculpture_number}>
        <img
          src={props.sculpture.sculpture_image}
          alt={props.sculpture.sculpture_name}
        />
      </label>
    </li>
  );
};

export default Check;

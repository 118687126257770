import React from "react";
import Logo from "../assets/images/logo800x800_branco.png";
import classes from "./LogoInfo.module.css";

const logoInfo = (props) => (
  <div className={classes.Logo}>
    <img src={Logo} alt="Dialogos em Silencio" />
  </div>
);
export default logoInfo;

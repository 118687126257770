// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogueModal_dialogueModal__2FJh0 {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  position: absolute;\n  align-items: center;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 660px;\n  height: 660px;\n  background-color: Black;\n  z-index: 500;\n  right: 50%;\n  bottom: 50%;\n  transition: all 0.3s ease-in-out;\n  box-shadow: 5px 2px 2px rgb(83, 83, 83);\n}\n.DialogueModal_dialogueModal__2FJh0 p {\n  width: 90%;\n  font-size: 30px;\n  color: white;\n  margin-left: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/main/dialogueModal/DialogueModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,kBAAkB;EAClB,mBAAmB;EACnB,SAAS;EACT,QAAQ;EACR,gCAAgC;EAChC,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,WAAW;EACX,gCAAgC;EAChC,uCAAuC;AACzC;AACA;EACE,UAAU;EACV,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".dialogueModal {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  position: absolute;\n  align-items: center;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 660px;\n  height: 660px;\n  background-color: Black;\n  z-index: 500;\n  right: 50%;\n  bottom: 50%;\n  transition: all 0.3s ease-in-out;\n  box-shadow: 5px 2px 2px rgb(83, 83, 83);\n}\n.dialogueModal p {\n  width: 90%;\n  font-size: 30px;\n  color: white;\n  margin-left: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogueModal": "DialogueModal_dialogueModal__2FJh0"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Backdrop_Backdrop__zFzWk {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: 100;\n  left: 0;\n  top: 0;\n  background-color: rgba(255, 255, 255, 0.8);\n  transition: all 0.3s ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://src/UI/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,OAAO;EACP,MAAM;EACN,0CAA0C;EAC1C,gCAAgC;AAClC","sourcesContent":[".Backdrop {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: 100;\n  left: 0;\n  top: 0;\n  background-color: rgba(255, 255, 255, 0.8);\n  transition: all 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": "Backdrop_Backdrop__zFzWk"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SculptureFilterMenu_filterMenuList__1u7Q2 {\n  right: 3%;\n  bottom: 10px;\n  position: fixed;\n  justify-content: center;\n  width: 500px;\n  height: 875px;\n  background-color: white;\n  z-index: 500;\n  transition: all 0.3s ease-in-out;\n  box-shadow: 5px 2px 2px rgb(78, 78, 78);\n  overflow-y: scroll;\n}\n.SculptureFilterMenu_filterMenuList__1u7Q2 h3 {\n  margin-left: 40px;\n}\n\n.SculptureFilterMenu_filterMenuList__1u7Q2 ul {\n  display: flex;\n  flex-wrap: wrap;\n  list-style-type: none;\n}\n", "",{"version":3,"sources":["webpack://src/main/sculptureFilterMenu/SculptureFilterMenu.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,gCAAgC;EAChC,uCAAuC;EACvC,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,qBAAqB;AACvB","sourcesContent":[".filterMenuList {\n  right: 3%;\n  bottom: 10px;\n  position: fixed;\n  justify-content: center;\n  width: 500px;\n  height: 875px;\n  background-color: white;\n  z-index: 500;\n  transition: all 0.3s ease-in-out;\n  box-shadow: 5px 2px 2px rgb(78, 78, 78);\n  overflow-y: scroll;\n}\n.filterMenuList h3 {\n  margin-left: 40px;\n}\n\n.filterMenuList ul {\n  display: flex;\n  flex-wrap: wrap;\n  list-style-type: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenuList": "SculptureFilterMenu_filterMenuList__1u7Q2"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contacts_content__2gRbc {\n  width: 60%;\n  height: 100%;\n  right: 0;\n  background-color: white;\n  color: Black;\n  z-index: 100;\n}\n", "",{"version":3,"sources":["webpack://src/contacts/Contacts.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,uBAAuB;EACvB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".content {\n  width: 60%;\n  height: 100%;\n  right: 0;\n  background-color: white;\n  color: Black;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Contacts_content__2gRbc"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
//import filterButton from "../../assets/images/funnel.png";
import classes from "./NavButton.module.css";
import { useDispatch } from "react-redux";

const Navbutton = (props) => {
  const dispatch = useDispatch();

  const sideMenuHandler = () => {
    dispatch({ type: "sideMenuToggle" });
  };

  return (
    <div className={classes.navButton} onClick={sideMenuHandler}>
      {props.children}
    </div>
  );
};

export default Navbutton;

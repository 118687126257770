import React from "react";
import classes from "./SculptureImg.module.css";

const SculptureImg = (props) => {
  return (
    <div className={classes.sculptureImage}>
      <img src={props.imgSrc} alt="Imagem da Escultura" />
    </div>
  );
};

export default SculptureImg;
